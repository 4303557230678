import React from 'react';
import styled from '@emotion/styled'

import { LargeParagraph } from '../../../components/LargeParagraph';
import { Marked } from '../../../components/Marked';
import { Underlined } from '../../../components/routes/Underlined';

import { colors } from '../../../theme/colors';
import { dimensions } from '../../../theme/media';
import { useWindowDimensions } from '../hooks/useWindowDimensions';
import { APP_URL } from '../../../consts/consts';

import arrowDownIcon from './assets/arrow-down.svg';
import startUsingIcon from './assets/start-using.svg';
import blueTriangleImage from './assets/blue-triangle.svg';

const Container = styled.div`
  min-height: 700px;
  background-color: ${colors.landingWhite};
  ${dimensions.tablet} {
    min-height: 600px;
  }
  ${dimensions.mobile} {
    min-height: 500px;
  }
`;

const Content = styled.div`
  background-color: ${colors.landingBlue};
  display: flex;
  align-items: center;
  flex-direction: column;
  ${dimensions.tablet} {
    padding: 20px;
  }
  ${dimensions.mobile} {
    padding: 10px;
  }
`;

const ParagraphContainer = styled.div`
  padding: 20px 0 100px 0;
  ${dimensions.tablet} {
    padding: 10px 0 80px 0;
  }
  ${dimensions.mobile} {
    padding: 0 0 60px 0;
  }
`;

const StartUsingIcon = styled(props => <img {...props} alt="start-using" />)`
  width: 120px;
  margin-top: 100px;
  ${dimensions.tablet} {
    width: 100px;
    margin-top: 80px;
  }
  ${dimensions.mobile} {
    height: 80px;
    margin-top: 60px;
  }
`;

const ArrowDownIcon = styled(props => <img {...props} alt="arrow-down" />)`
  width: 20px;
  margin-top: 30px;
  ${dimensions.tablet} {
    width: 19px;
    margin-top: 20px;
  }
  ${dimensions.mobile} {
    height: 19px;
    margin-top: 15px;
  }
`;

const TriangleImage = styled(props => <img {...props} alt="purple-triangle" />)`
  margin-top: -1px;
  width: 100%;
`;

const Link = styled.a`
  text-decoration: none;
  color: ${colors.landingWhite};
`;

export const StartUsingSection = () => {
  const { mobile } = useWindowDimensions();
  return (
    <Container>
      <Content>
        <StartUsingIcon src={startUsingIcon} />
        <ArrowDownIcon src={arrowDownIcon} />
      </Content>
      <Content>
        <ParagraphContainer>
          <LargeParagraph color={colors.landingWhite}>
            {mobile ? (
              <>
                <Link href={APP_URL}><Underlined>Start using</Underlined></Link> for <Marked>free</Marked> forever & play an instrumental
                role in shaping the product as an early adopter
              </>
            ) : (
              <>
                <span><Link href={APP_URL}><Underlined>Start using</Underlined></Link> for <Marked>free</Marked> forever</span><br /> & play an instrumental
                role in shaping<br /> the product as an early adopter
              </>
            )}
          </LargeParagraph>
        </ParagraphContainer>
      </Content>
      <TriangleImage src={blueTriangleImage} />
    </Container>
  );
};
