/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:12623420-8371-4c47-a719-5c6a5cad3958",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_edRZezI0b",
    "aws_user_pools_web_client_id": "5etau0ern6uot6rgbs7dcj8ddp",
    "oauth": {}
};


export default awsmobile;
