import React from 'react';
import styled from '@emotion/styled';

import { Header } from '../Header';
import { Footer } from '../Footer';
import { JoinOurTeamSection } from '../../sections/careers/JoinOurTeamSection';
import { OurCultureSection } from '../../sections/careers/our-culture-section/OurCultureSection';

import { dimensions } from '../../theme/media';

const PageContainer = styled.div`
  
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  ${dimensions.mobile} {
    padding: 0 15px;
  }
`;

const Content = styled.div`
  width: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Separator = styled.div`
  height: 60px;
`;

const CareersUpPage = ({ location }) => {

  return (
    <PageContainer>
      <Header location={location} />
      <Container>
        <Content>
          <JoinOurTeamSection />
          <Separator />
          <OurCultureSection />
        </Content>
      </Container>
      <Footer />
    </PageContainer>
  );
};

export default CareersUpPage;
