import React from 'react';

import { Header } from '../Header';
import { Footer } from '../Footer';
import { HowToPartnerUpSection } from '../../sections/partner-up/how-to-partner-up-section/HowToPartnerUpSection';

const PartnerUpPage = ({ location }) => {

  return (
    <div>
      <Header location={location} />
      <HowToPartnerUpSection />
      <Footer />
    </div>
  );
};

export default PartnerUpPage;
