import React from 'react';
import Loader from 'react-loader-spinner';

import { colors } from '../theme/colors';

export const CirclesLoader = ({ width, height }) => {
  return (
    <Loader
      type="Circles"
      color={colors.green}
      width={width || 24}
      height={height || 24}
    />
  );
};
