import React from 'react';
import styled from '@emotion/styled';
import { Link as GatsbyLink } from 'gatsby';

import { LargeParagraph } from '../../../components/LargeParagraph';
import { Paragraph } from '../../../components/Paragraph';
import { Divider } from '../../../components/Divider';
import { FeaturesList } from './components/FeaturesList';
import { TemplatesList } from './components/TemplatesList';

import { colors } from '../../../theme/colors'

import purpleTriangle from './assets/purple-triangle.svg';
import airBnbIcon from './assets/airbnb-icon.svg';
import saasIcon from './assets/saas-icon.svg';
import wealthIcon from './assets/wealth-icon.svg';

const Container = styled.div`
  background-color: ${colors.grey};
  min-height: 900px;
`;

const Content = styled.div`
  min-height: 800px;
  background-color: ${colors.landingGrey};
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const Link = styled((props) => <GatsbyLink {...props} />)`
  text-align: center;
  color: ${colors.landingWhite};
  font-family: 'Lato';
  text-decoration: underline;
  font-size: 1.1rem;
  letter-spacing: 0.15rem;
  cursor: pointer;
`;

const TriangleImage = styled(props => <img {...props} alt="purple-triangle" />)`
  margin-top: -1px;
  width: 100%;
`;

const items = [
  'Less Repetitive',
  'Large data sets without crashing',
  'Real time error tracking',
  'Free forever',
];

const templates = [{
  icon: saasIcon,
  description: 'SAAS',
  templateId: '0',
}, {
  icon: airBnbIcon,
  description: 'AirBnB',
  templateId: '1',
}, {
  icon: wealthIcon,
  description: 'Wealth Calculator',
  templateId: '2',
}];

export const WhoShouldUseSection = () => {
  return (
    <Container>
      <Content>
        <Divider height={120} />
        <LargeParagraph color={colors.landingWhite}>WHO SHOULD USE IT</LargeParagraph>
        <Divider height={40} />
        <Paragraph color={colors.landingWhite}>
          Try a new way to build models with natural language
        </Paragraph>
        <Divider height={60} />
        <FeaturesList items={items} />
        <Divider height={120} />
        <Paragraph color={colors.landingWhite}>LOAD TEMPLATES</Paragraph>
        <Divider height={80} />
        <TemplatesList templates={templates} />
        <Divider height={80} />
        <Link color={colors.landingWhite} to="/templates">View more</Link>
        <Divider height={80} />
      </Content>
      <TriangleImage src={purpleTriangle} />
    </Container>
  );
};
