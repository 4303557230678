import React from 'react';
import styled from '@emotion/styled'

import { GreenLine } from './GreenLine'

import { colors } from '../theme/colors'
import { dimensions } from '../theme/media';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ alignLeft }) => alignLeft ? 'left' : 'center'};
  padding-top: ${({ alignLeft }) => alignLeft ? '0' : '80'}px;
  ${dimensions.mobile} {
    padding-top: ${({ alignLeft }) => alignLeft ? '0' : '40'}px;
    align-items: center;
  }
`;

const Header = styled.div`
  font-size: 38px;
  margin-top: 40px;
  letter-spacing: 4px;
  font-family: 'Lato';
  color: ${colors.lightGrey};
  ${dimensions.mobile} {
    padding: 0 15px;
    text-align: center;
    margin-top: 20px;
    font-size: 20px;
    letter-spacing: 3px;
  }
`;

export const SectionHeader = ({ text, alignLeft }) => {
  return (
    <Content alignLeft={alignLeft}>
      <GreenLine />
      <Header>{text}</Header>
    </Content>
  );
};
