import React from 'react';
import styled from '@emotion/styled';

import { colors } from '../theme/colors';
import { Link as GatsbyLink } from 'gatsby';
import { dimensions } from '../theme/media';

const ContainerStyle = styled.div`
  bottom: 50%;
  transform: translateX(0) translateY(100%) rotate(-90deg);
  position: fixed;
  right: -1px;
  transform-origin: right bottom;
  z-index: 100000;
  padding: 5px 10px;
  background-color: #28777c;
  border: 1px solid #15a2a1;
  color: ${colors.landingWhite};
  :hover {
    background-color: #428e93;
  }
  cursor: pointer;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

const StyledLink = styled((props) => <GatsbyLink {...props} />)`
  text-decoration: none;
  color: ${colors.landingWhite};
  font-family: 'Lato';
  font-size: 16px;
  letter-spacing: 1.5px;
  ${dimensions.mobile} {
    font-size: 16px;
    letter-spacing: 2px;
    line-height: 14px;
  }
`;

export const TryForFreeBanner = ({ to, text }) => {
  return (
    <ContainerStyle>
      <StyledLink to={to}>{text}</StyledLink>
    </ContainerStyle>
  );
};
