import React, { useState } from 'react';
import styled from '@emotion/styled'

import { colors } from '../theme/colors'
import { dimensions } from '../theme/media'
import { post, subjectTypes } from '../api/api';
import { CirclesLoader } from './CirclesLoader';

const Container = styled.div`
  position: relative;
  ${dimensions.mobile} {
    display: flex;
  }
`;

const LoaderContainer = styled.div`
  position: absolute;
  right: 24px;
  top: 7px;
`;

const Input = styled.input`
  width: 320px;
  padding: 0 20px;
  background-color: ${colors.darkGrey};
  font-size: 16px;
  letter-spacing: 2px;
  font-family: 'Lato';
  color: ${colors.lightGrey};
  height: 40px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border: unset;
  :focus {
    outline: unset;
  }
  ::placeholder {
    color: ${colors.lightGrey}A6;
  }
  ${({ lightVersion }) => lightVersion ? `
    background-color: ${colors.grey};
  ` : ''}
  ${dimensions.mobile} {
    width: 200px;
    height: 36.5px;
    border-top-left-radius: 18px;
    border-bottom-left-radius: 18px;
    font-size: 16px;
    letter-spacing: 2px;
    line-height: 22px;
  }
  @media (max-width: 330px) {
    width: 185px;
  }
`;

const Button = styled.button`
  min-width: 70px;
  height: 40px;
  position: relative;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border: 1px solid ${colors.green};
  color: ${colors.green};
  font-family: 'Lato';
  font-size: 16px;
  background-color: ${colors.grey};
  ${({ isLoading }) => isLoading ? `color: ${colors.darkGrey};` : ''}
  ${({ lightVersion }) => lightVersion ? `
    background-color: ${colors.darkGrey};
  ` : ''}
  padding: 0 15px;
  letter-spacing: 1.5px;
  cursor: pointer;
  :focus {
    outline: none;
  }
  transition: background-color 0.3s ease;
  :hover {
    background-color: ${colors.green}26;
  }
  ${dimensions.mobile} {
    padding: 0 10px;
    height: 36px;
    border-top-right-radius: 18px;
    border-bottom-right-radius: 18px;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 22px;
  }
`;

const Description = styled.div`
  font-size: 15px;
  margin-top: 8px;
  letter-spacing: 2px;
  font-family: 'Lato';
  position: absolute;
  ${({ warning, success }) => `color: ${warning ? colors.red : success ? colors.green : `${colors.lightGrey}80`};`}
  ${({ background }) => `background: ${background ? background : `${colors.grey}`};`}
  width: 450px;
  ${dimensions.mobile} {
    font-size: 16px;
    margin-top: 45px;
    letter-spacing: 2px;
    line-height: 22px;
    width: 100%;
  }
`;

export const InputButton = ({ lightVersion, setSuccess, background }) => {
  const [value, setValue] = useState('');
  const [valueIsEmpty, setValueIsEmpty] = useState(false);
  const [error, setError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    if (value === '') {
      setSuccess ? setSuccess(false) : setIsSuccess(false);
      return setValueIsEmpty(true);
    }

    try {
      setIsLoading(true);
      await post({
        email: value,
        subject: subjectTypes.registerToAlpha,
      });
    } catch(e) {
      setIsLoading(false);
      return setError(true);
    }
    setIsLoading(false);
    setValue('');
    setSuccess ? setSuccess(true) : setIsSuccess(true);
  };

  return (
    <Container>
      <Input
        value={value}
        onChange={e => { setValueIsEmpty(false); setValue(e.target.value); }}
        placeholder="E-mail address"
        lightVersion={lightVersion}
      />
      <Button lightVersion={lightVersion} isLoading={isLoading} onClick={handleClick}>
        {isLoading ? <LoaderContainer><CirclesLoader /></LoaderContainer> : 'REGISTER'}
        {isLoading && '___'}
      </Button>
      {valueIsEmpty &&
      <Description background={background} warning={valueIsEmpty}>Please enter you E-mail address</Description>}
      {error &&
      <Description background={background} warning={error}>Unable to save your information</Description>}
      {isSuccess && (
        <Description background={background} success={isSuccess}>
          Thank you for applying for our closed alpha program.<br />We will get back to you soon!
        </Description>)}
    </Container>
  );
};
