export const colors = {
  grey: '#242535',
  darkGrey: '#191824',
  lightGrey: '#C8CBD3',
  green: '#11BBB8',
  blue: '#5BA2E4',
  purple: '#9852A8',
  red: '#DB4D4D',
  landingWhite: '#efefef',
  landingGrey: '#353448',
  landingBlue: '#2F405D',
};
