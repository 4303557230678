import React from 'react';
import styled from '@emotion/styled';

import { dimensions } from '../theme/media';

const Item = styled.div`
  height: ${({ height }) => height}px;
  ${dimensions.tablet} {
    height: ${({ tabletHeight }) => tabletHeight}px;
  }
  ${dimensions.mobile} {
    height: ${({ mobileHeight }) => mobileHeight}px;
  }
`;

export const Divider = ({ height, tabletHeight, mobileHeight }) => {
  return <Item height={height} tabletHeight={tabletHeight} mobileHeight={mobileHeight} />;
};
