import React from 'react';
import styled from '@emotion/styled';

const Text = styled.span`
  font-weight: bold;
`;

export const Bold = ({ children }) => {
  return <Text>{children}</Text>;
};
