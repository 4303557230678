import React from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
  max-width: 1400px;
  margin: 0 auto;
`;

export const SectionContainer = ({ children }) => {
  return (
    <Container>
      {children}
    </Container>
  );
};
