import React from 'react';
import styled from '@emotion/styled'
import { Link as GatsbyLink } from 'gatsby'

import { colors } from '../theme/colors'
import { dimensions } from '../theme/media';

const Container = styled.button`
  height: 32px;
  border-radius: 16px;
  border: 1px solid ${colors.green};
  background-color: ${colors.grey};
  padding: 0 15px;
  cursor: pointer;
  :focus {
    outline: none;
  }
  transition: background-color 0.3s ease;
  :hover {
    background-color: ${colors.green}26;
  }
  ${({ type }) => type === 'solid' ? `
    background-color: ${colors.green}8C;
    border: 1px solid ${colors.green}99;
    :hover {
      background-color: ${colors.green}B3;
    }
  ` : ''}
  margin-left: 20px;
  ${dimensions.mobile} {
    padding: 0 10px;
    height: 23px;
    margin: 2px 0;
    border-radius: 13px;
  }
`;

const StyledLink = styled((props) => <GatsbyLink {...props} />)`
  text-decoration: none;
  color: ${colors.green};
  ${({ type }) => type === 'solid' ? `
    color: ${colors.landingWhite};
  ` : ''}
  font-family: 'Lato';
  font-size: 16px;
  letter-spacing: 1.5px;
  ${dimensions.mobile} {
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 14px;
  }
`;

export const Button = ({ text, to, type }) => {
  return (
    <Container type={type}>
      <StyledLink type={type} to={to}>{text}</StyledLink>
    </Container>
  )
};
