import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby'
import Fade from 'react-reveal/Fade';

import { Button } from './Button'
import { Link } from './Link';

import { colors } from '../theme/colors'
import { dimensions } from '../theme/media';
import { APP_URL } from '../consts/consts';

const Container = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  ${({ isOnTop }) => isOnTop ? '' : `
    border-bottom: 1px solid ${colors.green}09;
    box-shadow: 0 3px 6px ${colors.lightGrey}09;
  `}
`;

const Content = styled.div`
  background-color: ${colors.grey};
  height: 80px;
  display: flex;
  flex-direction: row;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
  position: relative;
  ${dimensions.mobile} {
    height: 60px !important;
  }
`;

const LeftContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Text = styled.div`
  text-align: center;
  font-size: 20px;
  letter-spacing: 4px;
  font-family: 'Lato';
  color: ${colors.lightGrey};
  padding-left: 20px;
`;

const Links = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const MobileLinks = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${colors.grey};
  padding: 5px 0 20px 0;
`;

const DisableOnMobile = styled.div`
  ${dimensions.mobile} {
    display: none;
  }
  ${dimensions.tablet} {
    display: none;
  }
`;

const DisableOnDesktop = styled.div`
  ${dimensions.desktop} {
    display: none;
  }
`;

const StyledImg = styled((props) => <Img {...props} />)`
  ${dimensions.mobile} {
    width: 30px !important;
  }
`;

const CredentialButtonsStyle = styled.div`
  ${dimensions.mobile} {
    display: flex;
    flex-direction: column;
  }
`;

export const Header = ({ location, removeNavigation }) => {
  const [isOnTop, setIsOnTop] = useState(true);
  const { image: { childImageSharp: { fixed } } } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(width: 40) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  const listenToScroll = () => setIsOnTop(window.pageYOffset === 0);
  useEffect(() => {
    window.addEventListener('scroll', listenToScroll);
  }, []);
  return (
    <Container isOnTop={isOnTop}>
      <Content>
        <LeftContent>
          <StyledImg fixed={fixed} imgStyle={{ objectFit: 'contain' }} />
          {!isOnTop && <Text><Fade left cascade>AIMREX</Fade></Text>}
        </LeftContent>
        {!removeNavigation && (
          <DisableOnMobile>
            <Links>
              <Link to="/" label="Home" pathname={location.pathname} />
              <Link to="/templates" label="Templates" pathname={location.pathname} />
              <Link to="/partner-up" label="Partner Up" pathname={location.pathname} />
              <Link to="/careers" label="Careers" pathname={location.pathname} />
            </Links>
          </DisableOnMobile>
        )}
        {!removeNavigation && (
          <CredentialButtonsStyle>
            <Button text="TRY FOR FREE" to={APP_URL} type="solid" />
            <Button text="LOGIN" to={APP_URL} />
          </CredentialButtonsStyle>)}
      </Content>
      {!removeNavigation && (
        <DisableOnDesktop>
          <MobileLinks>
            <Link to="/" label="Home" pathname={location.pathname} />
            <Link to="/templates" label="Templates" pathname={location.pathname} />
            <Link to="/partner-up" label="Partner Up" pathname={location.pathname} />
            <Link to="/careers" label="Careers" pathname={location.pathname} />
          </MobileLinks>
        </DisableOnDesktop>
      )}
    </Container>
  );
};
