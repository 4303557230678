import React from 'react';
import styled from '@emotion/styled';

import { dimensions } from '../theme/media';

const Header = styled.h1`
  font-family: 'Lato';
  font-size: 4.4rem;
  letter-spacing: 1.1rem;
  font-weight: 100;
  margin-bottom: 45px;
  margin: 90px 0 45px 0;
  ${({ centerAlign }) => centerAlign ? 'text-align: center;' : ''}
  ${dimensions.tablet} {
    font-size: 3rem;
    letter-spacing: 2rem;
    line-height: 3.2rem;
  }
  ${dimensions.mobile} {
    font-size: 2rem;
    letter-spacing: 0.5rem;
    line-height: 2.2rem;
  }
`;

export const Headline = ({ title, centerAlign }) => {
  return (
    <Header centerAlign={centerAlign}>{title}</Header>
  );
};
