import React from 'react';
import styled from '@emotion/styled';

import { LargeParagraph } from '../../../../components/LargeParagraph';

import { colors } from '../../../../theme/colors';

const Container = styled.div`
  width: 100%;
`;

const ParagraphContainer = styled.div`
  padding: 20px;
  margin-bottom: 40px;
`;

const Card = styled.div`
  background-color: white;
  padding-top: 10px;
`;

export const MobileFeatures = () => {

  return (
    <Container>
      <Card>
        <div style={{ padding: '56.76% 0 0 0', position: 'relative' }}>
          <iframe
            src="https://player.vimeo.com/video/498313633?autoplay=1&loop=1&title=0&byline=0&portrait=0&autopause=0&muted=1"
            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', borderRadius: 6 }}
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          />
        </div>
        <ParagraphContainer>
          <LargeParagraph color={colors.landingGrey}>Dynamic tables to store data that handles units</LargeParagraph>
        </ParagraphContainer>
      </Card>
      <Card>
        <div style={{ padding: '57.76% 0 0 0', position: 'relative' }}>
          <iframe
            src="https://player.vimeo.com/video/498315069?autoplay=1&loop=1&title=0&byline=0&portrait=0&autopause=0&muted=1"
            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', borderRadius: 6 }}
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          />
        </div>
        <ParagraphContainer>
          <LargeParagraph color={colors.landingGrey}>Model using natural language 4x faster than Excel</LargeParagraph>
        </ParagraphContainer>
      </Card>
      <Card>
        <div style={{ padding: '65.74% 0 0 0', position: 'relative', borderRadius: 6, overflow: 'hidden' }}>
          <iframe
            src="https://player.vimeo.com/video/498839840?autoplay=1&loop=1&title=0&byline=0&portrait=0&autopause=0&muted=1"
            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          />
        </div>
        <ParagraphContainer>
          <LargeParagraph color={colors.landingGrey}>Create visuals linked directly to your data and models​</LargeParagraph>
        </ParagraphContainer>
      </Card>
    </Container>
  );
};
