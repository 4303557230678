import React, { useEffect, useState } from 'react'

import { HeadlineSection } from '../../sections/home/headline-section/HeadlineSection'
import { Header } from '../Header'
import { InAlphaRelease } from '../../sections/home/in-alpha-release/InAlphaRelease'
import { FeaturesSection } from '../../sections/home/features-section/FeaturesSection'
import { StartUsingSection } from '../../sections/home/start-using-section/StartUsingSection'
import { UnderDevelopmentSection } from '../../sections/home/under-development-section/UnderDevelopmentSection'
import { WhoShouldUseSection } from '../../sections/home/who-should-use-section/WhoShouldUseSection'
import { Footer } from '../Footer'
import { FooterBanner } from '../../sections/home/footer-banner/FooterBanner';
import { OurVisionSection } from '../../sections/home/our-vision-section/OurVisionSection';
import { TryForFreeBanner } from '../TryForFreeBanner';

import { APP_URL } from '../../consts/consts';

const HomePage = ({ location }) => {
  const [isOnTop, setIsOnTop] = useState(true);

  const listenToScroll = () => setIsOnTop(window.pageYOffset === 0);
  useEffect(() => {
    window.addEventListener('scroll', listenToScroll);
  }, []);

  return (
    <div>
      <Header location={location} />
      <HeadlineSection />
      <InAlphaRelease />
      <FeaturesSection />
      <StartUsingSection />
      <UnderDevelopmentSection />
      <WhoShouldUseSection />
      <OurVisionSection />
      <FooterBanner />
      <Footer />
      {!isOnTop && <TryForFreeBanner to={APP_URL} text="TRY FOR FREE" />}
    </div>
  );
};

export default HomePage
