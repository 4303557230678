import React from 'react';
import styled from '@emotion/styled'

import { LargeParagraph } from '../../../components/LargeParagraph';
import { MobileFeatures } from './components/MobileFeaturs';
import { DesktopAndTabletFeatures } from './components/DesktopAndTabletFeatures';

import { useWindowDimensions } from '../hooks/useWindowDimensions';
import { colors } from '../../../theme/colors'

const Container = styled.div`
  background-color: ${colors.landingWhite};
  min-height: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 60px;
`;

const ParagraphContainer = styled.div`
  padding: 80px;
  margin-top: 50px;
`;

export const FeaturesSection = () => {
  const { mobile } = useWindowDimensions();

  return (
    <Container>
      <ParagraphContainer>
        <LargeParagraph color={colors.landingGrey}>FEATURES</LargeParagraph>
      </ParagraphContainer>
      {mobile ? <MobileFeatures /> : <DesktopAndTabletFeatures />}
    </Container>
  );
};
