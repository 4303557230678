import React from 'react';
import styled from '@emotion/styled'

import { colors } from '../theme/colors'
import { dimensions } from '../theme/media'

const Content = styled.div`
  background-color: ${colors.green};
  height: 3px;
  width: 80px;
  ${dimensions.mobile} {
    width: 50px;
  }
`;

export const GreenLine = () => {
  return <Content />
};
