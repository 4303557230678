import React from 'react';
import styled from '@emotion/styled';
import Img from 'gatsby-image';

import { dimensions } from '../../../../theme/media';
import { colors } from '../../../../theme/colors';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 50%;
  ${dimensions.mobile} {
    width: 100%;
  }
  padding: 10px 0;
  align-items: center;
`;

const Text = styled.div`
  font-size: 15px;
  letter-spacing: 1px;
  font-family: 'Lato';
  color: ${colors.lightGrey};
  padding-left: 20px;
`;

const StyledImg = styled((props) => <Img {...props} />)`
  ${dimensions.mobile} {
    width: 30px !important;
  }
`;

export const OpenPositionItem = ({ icon, text }) => {
  return (
    <Container>
      <StyledImg fixed={icon} imgStyle={{ objectFit: 'contain' }} />
      <Text>{text}</Text>
    </Container>
  );
};