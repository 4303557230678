import React from 'react';
import styled from '@emotion/styled';

import { dimensions } from '../theme/media';

const Text = styled.div`
  font-size: 1rem;
  letter-spacing: 0.2rem;
  text-align: ${({ align }) => align || 'center'};
  line-height: 2rem;
  font-family: 'Lato';
  color: ${({ color }) => color};
  ${dimensions.tablet} {
    text-align: center;
  }
  ${dimensions.mobile} {
    text-align: center;
  }
`;

export const SmallParagraph = ({ children, color, align }) => {
  return <Text color={color} align={align}>{children}</Text>
};
