import React from 'react';
import styled from '@emotion/styled'

import { colors } from '../../../../theme/colors';

import arrowBackground from '../assets/arrow-card.svg';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Item = styled.div`
  background-image: url(${arrowBackground});
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 95px;
  font-size: 1rem;
  letter-spacing: 0.2rem;
  font-family: 'Lato';
  text-align: center;
  color: ${colors.landingWhite};
  margin: 0 20px 20px 20px;
`;

export const FeaturesList = ({ items }) => {
  return (
    <Container>
      {items.map((item, index) => (
        <Item key={index}>
          {item}
        </Item>
      ))}
    </Container>
  );
};
