import React from 'react';
import styled from '@emotion/styled';

import { LargeParagraph } from '../../../../components/LargeParagraph';
import { Divider } from '../../../../components/Divider';

import { colors } from '../../../../theme/colors';

import whiteArrowImage from '../assets/white-arrow-background.svg';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { dimensions } from '../../../../theme/media';

const ParagraphContainer = styled.div`
  padding: 20px;
  width: ${({ width }) => width}px;
  margin-left: 50px;
`;

const ContentItem = styled.div`
  background-color: ${({ color }) => color};
  width: 100%;
  height: ${({ height }) => height}px;
  margin-top: 120px;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  width: 100%;
`;

const ArrowParagraphContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-image: url(${whiteArrowImage});
  width: 576px;
  height: 280px;
  background-size: contain;
  margin-right: 45px;
  @media (max-width: 1460px) {
    width: 400px;
    height: 194px;
  } 
`;

const ArrowParagraph = styled.div`
  width: 480px;
  margin-left: 30px;
  ${dimensions.tablet} {
    margin-left: 0;
  }
`;

export const DesktopAndTabletFeatures = () => {
  const { tablet } = useWindowDimensions();

  const videoStyle = { borderRadius: 15, marginTop: -80, outline: 'none' };
  return (
    <Container>
      <Divider height={tablet ? 10 : 30} />
      <ContentItem height={tablet ? 375 : 475} color="white">
        <iframe
          src="https://player.vimeo.com/video/498313633?autoplay=1&loop=1&autopause=0&muted=1"
          width="860"
          height="505"
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
          style={videoStyle}
        />
        <ParagraphContainer width={450}>
          <LargeParagraph color={colors.landingGrey}>Dynamic tables to store data that handles units</LargeParagraph>
        </ParagraphContainer>
      </ContentItem>
      <Divider height={tablet ? 20 : 80} />
      <ContentItem height={tablet ? 390 : 490} color={colors.landingWhite}>
        <ArrowParagraphContainer>
          <ArrowParagraph>
            <LargeParagraph color={colors.landingGrey}>Model using natural language 4x faster than Excel</LargeParagraph>
          </ArrowParagraph>
        </ArrowParagraphContainer>
        <iframe
          src="https://player.vimeo.com/video/498315069?autoplay=1&loop=1&autopause=0&muted=1"
          width="828"
          height="495"
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
          style={{ ...videoStyle, marginTop: 0 }}
        />
      </ContentItem>
      <Divider height={tablet ? 30 : 100} />
      <ContentItem height={tablet ? 410 : 510} color="white">
        <iframe
          src="https://player.vimeo.com/video/498839840?autoplay=1&loop=1&autopause=0&muted=1"
          width="800"
          height="550"
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
          style={videoStyle}
        />
        <ParagraphContainer width={500}>
          <LargeParagraph color={colors.landingGrey}>Create visuals linked directly to your data and models​</LargeParagraph>
        </ParagraphContainer>
      </ContentItem>
      <Divider height={tablet ? 10 : 40} />
    </Container>
  );
};
