import React from 'react';
import styled from '@emotion/styled';

import { Bold } from '../../../components/Bold';
import { LargeParagraph } from '../../../components/LargeParagraph';
import { Paragraph } from '../../../components/Paragraph';
import { Underlined } from '../../../components/routes/Underlined';

import { colors } from '../../../theme/colors';
import { dimensions } from '../../../theme/media';
import { APP_URL } from '../../../consts/consts';

export const Container = styled.div`
  background-color: ${colors.landingWhite};
  min-height: calc(100vh - 60px);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const Header = styled.div`
  font-size: 50px;
  font-family: 'Lato';
  letter-spacing: 4px;
  color: ${colors.lightGrey};
  text-align: center;
  line-height: 50px;
  ${dimensions.mobile} {
    font-size: 30px;
    letter-spacing: 3px;
    padding: 0 20px;
    text-align: center;
    line-height: 40px;
  }
`;

const Link = styled.a`
  text-decoration: none;
  color: ${colors.landingGrey};
`;

export const FooterBanner = () => {
  return (
    <Container>
      <Content>
        <LargeParagraph color={colors.landingGrey}>
          We are creating a new paradigm of <Bold>financial modelling</Bold>
        </LargeParagraph>
        <Paragraph color={colors.landingGrey}>
          Become an <Link href={APP_URL}><Underlined>Early Adopter</Underlined></Link>
        </Paragraph>
      </Content>
    </Container>
  );
};
