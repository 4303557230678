import React, { useState } from 'react';
import styled from '@emotion/styled';

import { SectionHeader } from '../../../components/SectionHeader';
import { SelectOptionField } from './components/SelectOptionField';
import { InputField } from '../../../components/InputField';
import { LargeButton } from './components/LargeButton';

import { colors } from '../../../theme/colors';
import { dimensions } from '../../../theme/media';
import { post, subjectTypes } from '../../../api/api';

const Container = styled.div`
  background-color: ${colors.grey};
  min-height: calc(100vh - 145px);
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  max-width: 900px;
`;

const Separator = styled.div`
  height: 50px;
  ${dimensions.mobile} {
    height: 20px;
  }
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 35px 0 25px 0;
`;

const Description = styled.div`
  font-size: 15px;
  letter-spacing: 2px;
  font-family: 'Lato';
  margin: -15px 0 30px 0;
  text-align: center;
  ${({ warning, success }) => `color: ${warning ? colors.red : success ? colors.green : `${colors.lightGrey}80`};`}
  ${({ background }) => `background: ${background ? background : `${colors.grey}`};`}
  width: 450px;
  ${dimensions.mobile} {
    font-size: 16px;
    margin-top: 10px;
    letter-spacing: 2px;
    width: unset;
`;

const partnerInfoInitialState = {
  iAmFinancialModeller: false,
  iAmDataProvider: false,
  firstName: '',
  lastName: '',
  email: '',
  sector: '',
};

export const HowToPartnerUpSection = () => {
  const [partnerInfo, setPartnerInfo] = useState(partnerInfoInitialState);
  const [valueIsEmpty, setValueIsEmpty] = useState(false);
  const [error, setError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    const { firstName, lastName, email, sector } = partnerInfo;
    if (firstName === '' || lastName === '' || email === '' || sector === '') {
      return setValueIsEmpty(true);
    }

    try {
      setLoading(true);
      await post({
        ...partnerInfo, subject: subjectTypes.partnerUp
      });
    } catch(e) {
      setLoading(false);
      return setError(true)
    }

    setLoading(false);
    setError(false);
    setPartnerInfo(partnerInfoInitialState);
    setIsSuccess(true);
  };

  const handleValueChange = (change) => {
    setValueIsEmpty(false);
    setIsSuccess(false);
    setPartnerInfo({ ...partnerInfo, ...change });
  };

  return (
    <Container>
      <Content>
        <SectionHeader text="How to partner up with us" />
        <Separator />
        <SelectOptionField
          value={partnerInfo.iAmFinancialModeller}
          onChange={iAmFinancialModeller => handleValueChange({ iAmFinancialModeller })}
          header="Are you an expert financial modeller?"
          description="Become a Aimrex master modeller and contribute your projects to our community project hub. Select option if you are interested to learn more about how you can contribute"
        />
        <Inputs>
          <InputField
            placeholder="First Name"
            value={partnerInfo.firstName}
            onChange={firstName => handleValueChange({ firstName })}
          />
          <InputField
            placeholder="Last Name"
            value={partnerInfo.lastName}
            onChange={lastName => handleValueChange({ lastName })}
          />
          <InputField
            placeholder="Email Address"
            value={partnerInfo.email}
            onChange={email => handleValueChange({ email })}
          />
          <InputField
            placeholder="Sector"
            value={partnerInfo.sector}
            onChange={sector => handleValueChange({ sector })}
          />
          <Separator />
          {valueIsEmpty && <Description warning={valueIsEmpty}>Please fill in input fields.</Description>}
          {error && <Description warning={error}>Unable to save your information.</Description>}
          {isSuccess && <Description success={isSuccess}>Thank you for expressing your interest. We will get back to you soon!</Description>}
          <LargeButton text="SUBMIT" onClick={handleClick} loading={loading} />
          <Separator />
        </Inputs>
      </Content>
    </Container>
  );
};
