import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from '@emotion/styled';
import Img from 'gatsby-image'

import { colors } from '../../../../theme/colors';
import { dimensions } from '../../../../theme/media';

const Container = styled.div`
  max-width: 800px;
  margin-top: 40px;
  display: flex;
  flex-direction: row;
`;

const LeftContent = styled.div`
  max-width: 450px;
  ${dimensions.mobile} {
    padding-left: 20px;
  }
`;

const RightContent = styled.div`
  padding-left: 50px;
  display: flex;
  align-items: center;
  ${dimensions.mobile} {
    padding: 0 10px 0 20px;
  }
`;

const Header = styled.div`
  font-size: 17px;
  letter-spacing: 3px;
  font-family: 'Lato';
  color: ${colors.lightGrey}a3;
  margin-bottom: 5px;
  line-height: 34px;
  ${dimensions.mobile} {
    font-size: 17px;
    line-height: 26px;
  }
`;

const Description = styled.div`
  font-size: 15px;
  letter-spacing: 1.5px;
  font-family: 'Lato';
  color: ${colors.lightGrey};
  margin-top: 10px;
`;

const StyledImg = styled((props) => <Img {...props} />)`
  
`;

const ImageContainer = styled.div`
  cursor: pointer;
  width: 40px;
  height: 40px;
`;

export const SelectOptionField = ({ header, description, value, onChange }) => {
  const [hovered, setHovered] = useState(false);
  const {
    checkboxOff: { childImageSharp: { fixed: checkboxOff } },
    checkboxOn: { childImageSharp: { fixed: checkboxOn } },
  } = useStaticQuery(graphql`
    query {
      checkboxOff: file(relativePath: { eq: "checkbox-off.png" }) {
        childImageSharp {
          fixed(width: 40) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      checkboxHovered: file(relativePath: { eq: "checkbox-hovered.png" }) {
        childImageSharp {
          fixed(width: 40) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      checkboxOn: file(relativePath: { eq: "checkbox-on.png" }) {
        childImageSharp {
          fixed(width: 40) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  return (
    <Container>
      <LeftContent>
        <Header>{header}</Header>
        <Description>{description}</Description>
      </LeftContent>
      <RightContent>
        {value ? (
          <ImageContainer onClick={() => onChange(false)}>
            <StyledImg fixed={checkboxOn} />
          </ImageContainer> ) : (
          <ImageContainer
            onClick={() => onChange(true)}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >
            <StyledImg
              fixed={hovered ? checkboxOff : checkboxOff}
            />
          </ImageContainer>)}
      </RightContent>
    </Container>
  );
};
