import React from 'react';
import styled from '@emotion/styled';

import { colors } from '../theme/colors';
import { dimensions } from '../theme/media';

const Input = styled.input`
  width: 540px;
  padding: 0 25px;
  margin: 20px 0 0 0;
  background-color: ${colors.darkGrey};
  font-size: 16px;
  letter-spacing: 2px;
  font-family: 'Lato';
  color: ${colors.lightGrey};
  height: 50px;
  border-radius: 25px;
  border: unset;
  :focus {
    outline: unset;
  }
  ::placeholder {
    color: ${colors.lightGrey}A6;
  }
  ${({ inputCustomStyle }) => inputCustomStyle || ''}
  ${({ lightVersion }) => lightVersion ? `
    background-color: ${colors.grey};
  ` : ''}
  ${dimensions.mobile} {
    width: 260px;
    height: 36px;
    border-radius: 18px;
    font-size: 16px;
    letter-spacing: 2px;
    line-height: 22px;
  }
  @media (max-width: 330px) {
    width: 185px;
  }
`;

export const InputField = ({ placeholder, value, onChange, type, inputCustomStyle }) => {
  return (
    <Input
      type={type || 'text'}
      value={value}
      placeholder={placeholder}
      onChange={e => onChange(e.target.value)}
      inputCustomStyle={inputCustomStyle}
    />
  );
};
