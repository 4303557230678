import React from 'react'
import styled from '@emotion/styled';

import { Header } from '../Header';
import { Footer } from '../Footer';

import { SectionHeader } from '../SectionHeader';

import { colors } from '../../theme/colors';
import { dimensions } from '../../theme/media';

const Container = styled.div`
  background-color: ${colors.grey};
  min-height: 700px;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  max-width: 900px;
  ${dimensions.tablet} {
    padding: 0 20px;
  }
  ${dimensions.mobile} {
    padding: 0 20px;
  }
`;

const Text = styled.div`
  font-size: 15px;
  letter-spacing: 1.5px;
  font-family: 'Lato';
  color: ${colors.lightGrey};
  margin-top: 20px;
`;

const SubHeader = styled.div`
  font-size: 18px;
  letter-spacing: 1.5px;
  font-family: 'Lato';
  color: ${colors.lightGrey}a1;
  margin-top: 35px;
`;

const Separator = styled.div`
  height: 60px;
  ${dimensions.tablet} {
    height: 40px;
  }
  ${dimensions.mobile} {
    height: 30px;
  }
`;

const TermsAndConditions = ({ location }) => {

  return (
    <div>
      <Header location={location}/>
      <Container>
        <Content>
          <SectionHeader text="Terms and Conditions"/>
          <Separator/>
          <Text>
            The team at AIMREX cares about your privacy and the protection of your personal data. We must, however,
            process some data connected to you in order to deliver our service and continuously improve our product.
            This privacy policy explains which data we collect, how we use it and which rights you have in relation to
            our processing of your personal data.
          </Text>
          <Text>
            This privacy policy applies to Eucleides.is and AIMREX as a Software-as-a-Service (SaaS) solution in
            connection to which AIMREX is the data controller. AIMREX is, however, not a data controller for any
            personal information relating to other individuals that might be included in the files uploaded to our
            servers. In this instance, you or your company are the data controller and AIMREX the data processor. If
            you decide to publish any personal data, you are responsible to ensure that publishing such data is in line
            with legislation.
          </Text>
          <SubHeader>
            Which personal data do we need?
          </SubHeader>
          <Text>
            To be able to use AIMREX, you must supply us with your email address which will serve as your username.
            We will also ask you to provide your name, and optionally title, job role, industry and gender. We would
            also like to stay in touch with our users by sending out newsletters and surveys via email. When you use our
            product, we will also receive your IP address and some system- and diagnostic data which tells us how you
            use our product and how our system is performing.
          </Text>
          <SubHeader>
            Why do we collect your information?
          </SubHeader>
          <Text>
            We collect your email address to enable you to login to our system and keep track of you in our Customer
            Relations Management (CRM) system. You, however, fully control how much you share with us. We collect system
            data to analyse the use of our system, scale the performance to deliver enough computing power for all users
            and for further development of AIMREX.
          </Text>
          <SubHeader>
            For how long will we keep your personal data?
          </SubHeader>
          <Text>We will keep your personal data for as long as you are a registered user of AIMREX. If you decide to
            delete your account, we delete your data within 7 months.‍ </Text>
          <SubHeader>
            What is our legal basis for processing your data?
          </SubHeader>
          <Text>We need some of your personal data in order for you to be able to use AIMREX. If you have chosen to
            contact us we will process your personal data in order to be able to respond to you. We will send you emails
            with information about and surveys related to our product based on our interest as a business. You can,
            however, always opt-out from receiving such emails. </Text>
          <Text>We are also interested in analyzing the use of our product based on system usage data and other
            diagnostic data to be able to further develop AIMREX and ensure best performance. </Text>
          <SubHeader>
            Disclosure
          </SubHeader>
          <Text>
            We will only disclose your personal data if we are required to do so by law or court order. In all other
            cases, your personal data will not be shared.
          </Text>
          <SubHeader>
            Transfer of data outside the EU/EEA
          </SubHeader>
          <Text>
            We use third party providers to be able to deliver our services. Some of those are located outside the
            EU/EEA. In such instances the transfer of personal data is subject to appropriate safeguards such as
            standard data protection clauses adopted by the EU Commission.
          </Text>
          <SubHeader>Security</SubHeader>
          <Text>We handle your personal data in a secure way to ensure it is not inappropriately lost, misused,
            accessed, disclosed, altered or destroyed. Access to your data is provided to those that need it in order to
            be able to perform their jobs (i.e. the product marketing department have access to your email and personal
            information, while product developers have access to usage information). </Text>
          <SubHeader>Your rights</SubHeader>
          <Text>You may have a right to access, correct, amend or delete personal information we have about you as well
            as the right to data portability. You also may have a right to demand restriction of processing your
            personal data, objecting to processing and filing a complaint with the Data Protection Authority. We are
            committed to respect the data subject rights found in EU´s General Data Protection Regulation, GDPR. </Text>
          <SubHeader>Changes to this privacy policy</SubHeader>
          <Text>We reserve the right to modify this privacy policy at any time. Changes and clarifications will take
            effect immediately upon their posting on eucleides.com. If we make material changes to this policy, we will
            notify you that it has been updated so that you are aware of what information we collect, how we use it and
            under what circumstances, if any, we share and/or disclose it. If AIMREX is acquired or merged with
            another company, your information may be transferred to the new owners to keep delivering the
            product.</Text>
          <Text>For more information please contact us at info@eucleides.com</Text>
          <Separator/>
        </Content>
      </Container>
      <Footer/>
    </div>
  );
};

export default TermsAndConditions
