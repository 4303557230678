import React from 'react';
import styled from '@emotion/styled';

import { SectionHeader } from '../../components/SectionHeader';
import { Marked } from '../../components/Marked';

import { colors } from '../../theme/colors';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Description = styled.div`
  font-size: 17px;
  letter-spacing: 1.5px;
  font-family: 'Lato';
  color: ${colors.lightGrey};
  max-width: 520px;
  margin-top: 30px;
  text-align: center;
`;

const Separator = styled.div`
  height: 30px;
`;

export const JoinOurTeamSection = () => {
  return (
    <Container>
      <SectionHeader text="Join our Team" />
      <Separator />
      <Description>
        We are <Marked>reinventing financial modelling</Marked> by taking the best parts of Excel and supercharging it with the latest tech
      </Description>
      <Description>
        In short, we are building the BI tool for financial modelling
      </Description>
    </Container>
  );
};
