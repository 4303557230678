import React from 'react';
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { useLocation } from '@reach/router';

import { colors } from '../theme/colors'
import { dimensions } from '../theme/media';

const Container = styled.div`
  min-height: 60px;
  display: flex;
  justify-content: center;
  position: relative;
  background-color: ${({ color }) => color};
  ${({ backgroundColor }) => backgroundColor ? `background-color: ${backgroundColor};` : ''};
  ${dimensions.mobile} {
    flex-direction: column;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  ${dimensions.mobile} {
    flex-direction: column;
  }
`;

const Copyright = styled.div`
  font-size: 15px;
  letter-spacing: 1.5px;
  font-family: 'Lato';
  color: ${({ color }) => color}82;
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translate(0, -50%);
  ${dimensions.mobile} {
    position: inherit;
    top: unset;
    left: unset;
    transform: unset;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 10px 0;
  }
`;

const CenteredCopyright = styled.div`
  font-size: 15px;
  letter-spacing: 1.5px;
  font-family: 'Lato';
  color: ${({ color }) => color}82;
  text-align: center;
  padding-left: 10px;
`;

const StyledLink = styled(props => <Link {...props} />)`
  font-size: 15px;
  letter-spacing: 1.5px;
  font-family: 'Lato';
  color: ${({ color }) => color};
  text-decoration: none;
  padding: 0 25px;
  :hover {
    color: ${colors.green};
  }
  ${dimensions.mobile} {
    text-align: center;
    padding: 10px 0;
  }
`;

export const Footer = ({ hideLinks, backgroundColor }) => {
  const { pathname } = useLocation();
  return (
    <Container color={pathname === '/' ? colors.landingWhite : colors.grey} backgroundColor={backgroundColor}>
      {!hideLinks && (
        <>
          <Content>
            <StyledLink
              to="/terms-and-conditions/"
              color={pathname === '/' ? colors.landingGrey : colors.lightGrey}
            >
              T&C and Privacy Policy
            </StyledLink>
          </Content>
          <Copyright color={pathname === '/' ? colors.darkGrey : colors.lightGrey}>© 2020-2021 Aimrex</Copyright>
        </>
      )}
      {hideLinks && (
        <Content>
          <CenteredCopyright color={pathname === '/' ? colors.darkGrey : colors.lightGrey}>
            © 2020-2021 Aimrex
          </CenteredCopyright>
        </Content>
      )}
    </Container>
  );
};
