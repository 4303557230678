import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';

import { SectionContainer } from '../../../components/SectionContainer';
import { Marked } from '../../../components/Marked';
import { LargeParagraph } from '../../../components/LargeParagraph';
import { Headline } from '../../../components/Headline';

import { colors } from '../../../theme/colors';
import { useWindowDimensions } from '../hooks/useWindowDimensions';

const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: ${colors.grey};
  position: relative;
  min-height: calc(80vh - 80px);
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: lighter;
  width: 100%;
  color: ${colors.landingWhite};
  margin-top: -160px;
`;

export const HeadlineSection = () => {
  const { mobile } = useWindowDimensions();
  const {
    lightArrowDown: { childImageSharp: { fixed: iconDown } },
  } = useStaticQuery(graphql`
    query {
      lightArrowDown: file(relativePath: { eq: "light-arrow-down.png" }) {
        childImageSharp {
          fixed(width: 30) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  return (
    <Container>
      <SectionContainer>
        <Content>
          <Headline title="AIMREX" />
          <LargeParagraph color={colors.landingWhite}>
            {mobile ?
              <>Effortlessly build, manage and share <Marked>financial models</Marked> using low code</> :
              <>Effortlessly build, manage and share<br /> <Marked>financial models</Marked> using low code</>}
          </LargeParagraph>
        </Content>
      </SectionContainer>
    </Container>
  );
};
