import React from 'react';
import styled from '@emotion/styled'

const Content = styled.span`
  text-decoration: underline;
`;

export const Underlined = ({ children }) => {
  return (
    <Content>
      {children}
    </Content>
  );
};
