import React from 'react';
import styled from '@emotion/styled'

import { colors } from '../theme/colors'

const Content = styled.span`
  color: ${colors.green};
`;

export const Marked = ({ children }) => {
  return (
    <Content>
      {children}
    </Content>
  );
};
