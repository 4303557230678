import React from 'react';
import styled from '@emotion/styled';

import { LargeParagraph } from '../../../components/LargeParagraph';
import { Divider } from '../../../components/Divider';
import { SmallParagraph } from '../../../components/SmallParagraph';

import { colors } from '../../../theme/colors';
import { dimensions } from '../../../theme/media';

import ourVisionIcon from './assets/our-vision.svg';
import timelineIcon from './assets/timeline.svg';
import triangleIcon from './assets/dark-grey-triangle.svg';

const Container = styled.div`
  background-color: ${colors.landingWhite};
`;

const Content = styled.div`
  background-color: ${colors.grey};
  min-height: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TimelineContainer = styled.div`
  display: flex;
  flex-direction: row;
  ${dimensions.tablet} {
    flex-direction: column;
  }
  ${dimensions.mobile} {
    flex-direction: column;
  }
`;

const OurVision = styled(props => <img {...props} alt="our-vision-icon" />)`
  width: 150px;
  ${dimensions.tablet} {
    width: 130px;
  }
  ${dimensions.mobile} {
    width: 110px;
  }
`;

const Timeline = styled(props => <img {...props} alt="timeline-icon" />)`
  height: 800px;
  padding: 0 50px;
  opacity: 0.6;
  ${dimensions.tablet} {
    display: none;
  }
  ${dimensions.mobile} {
    display: none;
  }
`;

const TriangleImage = styled(props => <img {...props} alt="dark-grey-triangle" />)`
  margin-top: -1px;
  width: 100%;
`;

const Column = styled.div`
  width: 350px;
`;

export const OurVisionSection = () => {
  return (
    <Container>
      <Content>
        <Divider height={80} tabletHeight={60} mobileHeight={60} />
        <LargeParagraph color={colors.landingWhite}>2021 VISION</LargeParagraph>
        <Divider height={80} />
        <OurVision src={ourVisionIcon} />
        <Divider height={20} />
        <TimelineContainer>
          <Column>
            <Divider height={320} tabletHeight={60} mobileHeight={40} />
            <LargeParagraph color={colors.landingWhite} align="right">
              Model Better
            </LargeParagraph>
            <Divider height={25} />
            <SmallParagraph color={colors.landingWhite} align="right">
              Build models 4x faster<br /> than Excel
            </SmallParagraph>
            <Divider height={25} />
            <SmallParagraph color={colors.landingWhite} align="right">
              Pinpoint mistakes in real time to drive ZERO error models
            </SmallParagraph>
          </Column>
          <Timeline src={timelineIcon} />
          <Column>
            <Divider height={10} tabletHeight={60} mobileHeight={60} />
            <LargeParagraph color={colors.landingWhite} align="left">
              Manage Data Better
            </LargeParagraph>
            <Divider height={25} />
            <SmallParagraph color={colors.landingWhite} align="left">
              Collaborate with other users on the same project
            </SmallParagraph>
            <Divider height={25} />
            <SmallParagraph color={colors.landingWhite} align="left">
              Integrate with external data sources using built-in data connections
            </SmallParagraph>
            <Divider height={114} tabletHeight={60} mobileHeight={60} />
            <LargeParagraph color={colors.landingWhite} align="left">
              Visualise Better
            </LargeParagraph>
            <Divider height={25} />
            <SmallParagraph color={colors.landingWhite} align="left">
              Create more charts, animations and dashboards
            </SmallParagraph>
            <Divider height={25} />
            <SmallParagraph color={colors.landingWhite} align="left">
              Share models as<br /> interactive visuals
            </SmallParagraph>
          </Column>
        </TimelineContainer>
        <Divider height={40} />
      </Content>
      <TriangleImage src={triangleIcon} />
    </Container>
  );
};
