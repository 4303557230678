import React from 'react';
import styled from '@emotion/styled'

import { LargeParagraph } from '../../../components/LargeParagraph';
import { Bold } from '../../../components/Bold';
import { Paragraph } from '../../../components/Paragraph';

import { colors } from '../../../theme/colors';
import { dimensions } from '../../../theme/media';

import shareVisualsImage from './assets/share-visuals.svg';

const Container = styled.div`
  background-color: ${colors.landingWhite};
  min-height: 940px;
  padding-top: 80px;
  ${dimensions.tablet} {
    padding-top: 30px;
    min-height: 700px;
  }
  ${dimensions.mobile} {
    padding-top: 50px;
    min-height: 500px;
  }
`;

const UnderDevelopmentImage = styled(props => <img {...props} alt="purple-triangle" />)`
  width: 600px;
  margin-top: -80px;
  margin-right: 30px;
`;

const ImageContainer = styled.div`
  background-color: #262E42;
  border-radius: 20px;
  display: flex;
  justify-content: flex-end;
  margin-right: 50px;
  margin-left: -20px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 220px;
  ${dimensions.tablet} {
    flex-direction: column;
    align-items: center;
    padding-top: 190px;
  }
  ${dimensions.mobile} {
    flex-direction: column;
    align-items: center;
    padding-top: 140px;
  }
`;

const Column = styled.div`
  width: 50%;
  ${dimensions.tablet} {
    width: 100%;
  }
  ${dimensions.mobile} {
    width: 100%;
  }
`;

const ParagraphContainer = styled.div`
  padding-top: 140px;
  ${dimensions.tablet} {
    padding: 70px 0;
  }
  ${dimensions.mobile} {
    padding: 70px 0;
  }
`;

export const UnderDevelopmentSection = () => {
  return (
    <Container>
      <LargeParagraph color={colors.landingGrey}>UNDER DEVELOPMENT</LargeParagraph>
      <Content>
        <Column>
          <ImageContainer>
            <UnderDevelopmentImage src={shareVisualsImage} />
          </ImageContainer>
        </Column>
        <Column>
          <ParagraphContainer>
            <Paragraph color={colors.landingGrey}>
              Share your story as<br />
              <Bold>interactive dashboards</Bold><br />
              anywhere
            </Paragraph>
          </ParagraphContainer>
        </Column>
      </Content>
    </Container>
  );
};
