import React from 'react';
import styled from '@emotion/styled';

import { SmallParagraph } from '../../../../components/SmallParagraph';

import { colors } from '../../../../theme/colors';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const Template = styled.div`
  background-color: #474658;
  width: 260px;
  height: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  margin: 0 20px 20px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #545269;
  }
`;

const Image = styled.img`
  width: 90px;
  margin-top: 40px;
`;

const Link = styled.a`
  text-decoration: none;
`;

export const TemplatesList = ({ templates }) => {
  return (
    <Container>
      {templates.map((template, index) => (
        <Link href={`https://www.app.aimrex.com/?templateId=${template.templateId}`}>
          <Template key={index}>
            <Image src={template.icon} />
            <SmallParagraph align="center" color={colors.landingWhite}>{template.description}</SmallParagraph>
          </Template>
        </Link>
      ))}
    </Container>
  );
};
