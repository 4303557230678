import React from 'react';
import styled from '@emotion/styled';

import { dimensions } from '../theme/media';

const Text = styled.div`
  font-family: 'Lato';
  font-size: 1.6rem;
  letter-spacing: 0.35rem;
  text-align: center;
  line-height: 2.8rem;
  color: ${({ color }) => color};
  ${dimensions.tablet} {
    font-size: 1.4rem;
    letter-spacing: 0.3rem;
    line-height: 1.8rem;
  }
  ${dimensions.mobile} {
    font-size: 1.2rem;
    letter-spacing: 0.25rem;
    line-height: 1.5rem;
  }
`;

export const Paragraph = ({ children, color }) => {
  return <Text color={color}>{children}</Text>
};
