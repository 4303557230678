import React from 'react';
import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';

import { Marked } from '../../../components/Marked';
import { OpenPositionItem } from './components/OpenPositionItem';

import { colors } from '../../../theme/colors';
import { dimensions } from '../../../theme/media';

const Container = styled.div`
 
`;

const Header = styled.div`
  font-size: 30px;
  letter-spacing: 5px;
  margin: 5px 0;
  font-family: 'Lato';
  color: ${colors.lightGrey}a3;
  ${dimensions.mobile} {
    font-size: 22px;
  }
`;

const Text = styled.div`
  font-size: 17px;
  letter-spacing: 1.5px;
  font-family: 'Lato';
  color: ${colors.lightGrey};
  margin-top: 25px;
`;

const BulletText = styled.div`
  font-size: 17px;
  letter-spacing: 1.5px;
  font-family: 'Lato';
  color: ${colors.lightGrey};
  margin-top: 15px;
`;

const Line = styled.div`
  height: 2px;
  width: 45px;
  background-color: ${colors.green};
  margin-bottom: 15px;
  margin-top: 30px;
`;

const Separator = styled.div`
  height: 30px;
`;

const OpenPositions = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const OurCultureSection = () => {
  const {
    dataAnalystIcon: { childImageSharp: { fixed: dataAnalystIcon } },
    dataScientistIcon: { childImageSharp: { fixed: dataScientistIcon } },
    headOfGrowthIcon: { childImageSharp: { fixed: headOfGrowthIcon } },
    headOfMarketingIcon: { childImageSharp: { fixed: headOfMarketingIcon } },
    productManagerIcon: { childImageSharp: { fixed: productManagerIcon } },
    seniorBackendIcon: { childImageSharp: { fixed: seniorBackendIcon } },
    seniorFrontendIcon: { childImageSharp: { fixed: seniorFrontendIcon } },
  } = useStaticQuery(graphql`
    query {
      dataAnalystIcon: file(relativePath: { eq: "data-analyst.png" }) {
        childImageSharp {
          fixed(width: 35) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      dataScientistIcon: file(relativePath: { eq: "data-scientist.png" }) {
        childImageSharp {
          fixed(width: 30) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      headOfGrowthIcon: file(relativePath: { eq: "head-of-growth.png" }) {
        childImageSharp {
          fixed(width: 35) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      headOfMarketingIcon: file(relativePath: { eq: "head-of-marketing.png" }) {
        childImageSharp {
          fixed(width: 35) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      productManagerIcon: file(relativePath: { eq: "product-manager.png" }) {
        childImageSharp {
          fixed(width: 35) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      seniorBackendIcon: file(relativePath: { eq: "senior-backend.png" }) {
        childImageSharp {
          fixed(width: 35) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      seniorFrontendIcon: file(relativePath: { eq: "senior-frontend.png" }) {
        childImageSharp {
          fixed(width: 35) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  return (
    <Container>
      <Line />
      <Header>Our Culture</Header>
      <Text>We are a meritocratic and down to earth firm. We treat each other with respect and focus on <Marked>employee outcome rather than output</Marked>.  We also believe in true ownership and want all of our employees to participate in the company’s success.</Text>
      <Text>For us, the foundation of an evolving culture is based on openness, diversity, and the ability to adapt. We think that embracing our differences is an important ingredient for productivity, quality and growth. We prioritise our employee development, both professionally and personally, and drive them to be their best. Our culture is its people.</Text>
      <Text>Aimrex is an equal opportunity employer and encourages applicants from any national origin, sex, sexual orientation, religious background, gender identity, and those individuals with disabilities to apply.</Text>
      <Separator />
      <Line />
      <Header style={{ marginBottom: 25 }}>Why Us</Header>
      <BulletText>- Solve fascinating and hard problems that affect millions of users worldwide</BulletText>
      <BulletText>- Work with cutting-edge tech</BulletText>
      <BulletText>- Open & employee-driven company culture</BulletText>
      <BulletText>- Parent & remote-friendly environment with flexible working hours</BulletText>
      <BulletText>- Competitive salary, Employee Stock Options & great benefits</BulletText>
      <BulletText>- Backed by Europe’s finest entrepreneurs</BulletText>
      <Separator />
      <Line />
      <Header>Interested in Joining Us?</Header>
      <Text>Amazing! Please send us your CV, availability and salary expectations to <Marked>info@eucleides.com</Marked></Text>
      <Separator />
      <Separator />
      <Line />
      <Header>Open Positions</Header>
      <Separator />
      <OpenPositions>
        <OpenPositionItem icon={seniorFrontendIcon} text="Senior Frontend Developer" />
        <OpenPositionItem icon={seniorBackendIcon} text="Senior Backend Developer" />
        <OpenPositionItem icon={dataAnalystIcon} text="Data Analyst" />
        <OpenPositionItem icon={dataScientistIcon} text="Data Scientist" />
        <OpenPositionItem icon={headOfGrowthIcon} text="Head of Growth" />
        <OpenPositionItem icon={productManagerIcon} text="Product Manager" />
        <OpenPositionItem icon={headOfMarketingIcon} text="Head of Marketing" />
      </OpenPositions>
      <Separator />
      <Separator />
      <Separator />
      <Separator />
    </Container>
  );
};
