import React from 'react'
import styled from '@emotion/styled';

import { Header } from '../Header'
import { Footer } from '../Footer'
import { GreenLine } from '../GreenLine';
import { InputButton } from '../InputButton';

import { colors } from '../../theme/colors';
import { dimensions } from '../../theme/media';

import ComingSoonIcon from '../../images/coming-soon.svg';

const Container = styled.div`
  display: flex;
  min-height: calc(100vh - 140px);
  justify-content: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 140px 300px 0 0;
  min-height: 900px;
  ${dimensions.tablet} {
    padding-left: 20px;
  }
  ${dimensions.mobile} {
    padding: 30px 20px 40px 20px;
    align-items: center;
    min-height: 500px;
  }
`;

const Heading = styled.div`
  font-size: 40px;
  margin-top: 40px;
  letter-spacing: 10px;
  font-family: 'Lato';
  color: ${colors.lightGrey};
  position: relative;
  ${dimensions.tablet} {
    font-size: 32px;
    letter-spacing: 7px;
    line-height: 32px;
  }
  ${dimensions.mobile} {
    font-size: 30px;
    margin-top: 30px;
    letter-spacing: 10px;
    text-align: center;
    line-height: 30px;
  }
`;

const SubHeader = styled.div`
  font-size: 19px;
  margin-top: 22px;
  letter-spacing: 4px;
  font-family: 'Lato';
  color: ${colors.lightGrey};
  max-width: 700px;
  ${dimensions.tablet} {
    max-width: 500px;
    font-size: 17px;
    margin-top: 10px;
  }
  ${dimensions.mobile} {
    font-size: 16px;
    margin-top: 10px;
    letter-spacing: 3px;
    text-align: center;
  }
`;

const Form = styled.div`
  margin-top: 70px;
  ${dimensions.tablet} {
    margin-top: 40px;
  }
  ${dimensions.mobile} {
    margin-top: 20px;
  }
`;

const StyledComingSoonDesktopIcon = styled((props) => <ComingSoonIcon {...props} />)`
  position: absolute;
  width: 600px;
  height: 600px;
  top: -64px;
  right: -400px;
  ${dimensions.tablet} {
    right: -329px;
    top: -23px;
    width: 500px;
    height: 500px;
  }
  ${dimensions.mobile} {
    position: unset;
    width: 100%;
    padding: 30px;
    height: unset;
  }
`;

const ComingSoonPage = ({ location }) => {

  return (
    <div>
      <Header location={location} />
      <Container>
        <Content>
          <GreenLine />
          <Heading>Check back again soon<StyledComingSoonDesktopIcon /></Heading>
          <SubHeader>or register for our alpha to be whitelisted and notified when we are ready to launch in the coming weeks</SubHeader>
          <Form>
            <InputButton />
          </Form>
        </Content>
      </Container>
      <Footer />
    </div>
  );
};

export default ComingSoonPage;
