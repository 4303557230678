import React from 'react';
import styled from '@emotion/styled'

import { LargeParagraph } from '../../../components/LargeParagraph';

import { colors } from '../../../theme/colors';
import { dimensions } from '../../../theme/media';

import purpleTriangle from './assets/purple-triangle.svg';
import plantWithLeavesImg from './assets/plant-with-leaves.svg';

const Container = styled.div`
  background-color: ${colors.landingWhite};
`;

const Content = styled.div`
  height: 440px;
  background-color: ${colors.landingGrey};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  ${dimensions.tablet} {
    height: 360px;
  }
  ${dimensions.mobile} {
    height: 300px;
  }
`;

const TriangleImage = styled(props => <img {...props} alt="purple-triangle" />)`
  margin-top: -1px;
  width: 100%;
`;

const PlantImage = styled(props => <img {...props} alt="plant-with-leaves" />)`
  width: 330px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-left: 20px;
  ${dimensions.tablet} {
    width: 250px;
  }
  ${dimensions.mobile} {
    width: 200px;
  }
`;

const ParagraphContainer = styled.div`
  margin-top: 120px;
`;

export const InAlphaRelease = () => {
  return (
    <Container>
      <Content>
        <PlantImage src={plantWithLeavesImg} />
        <ParagraphContainer>
          <LargeParagraph color="white">Currently in<br />ALPHA RELEASE</LargeParagraph>
        </ParagraphContainer>
      </Content>
      <TriangleImage src={purpleTriangle} />
    </Container>
  )
};
