import 'isomorphic-fetch';
const API_URL = 'https://5p4arpkki8.execute-api.eu-west-2.amazonaws.com/prod/register';

export const subjectTypes = {
  registerToAlpha: 'registerToAlpha',
  partnerUp: 'partnerUp',
};

export async function post(body) {
  const result = await fetch(API_URL, { method: 'POST', body: JSON.stringify(body) });

  if (result.status >= 400) {
    throw Error('Server error');
  }
  return result;
}
