import React from 'react';
import styled from '@emotion/styled'
import { colors } from '../../../../theme/colors';
import { dimensions } from '../../../../theme/media';
import { CirclesLoader } from '../../../../components/CirclesLoader';

const Container = styled.button`
  height: 50px;
  border-radius: 25px;
  width: ${({ width }) => width || 200}px;
  border: 1px solid ${colors.green};
  color: ${colors.green};
  font-family: 'Lato';
  font-size: 16px;
  background-color: ${colors.grey};
  padding: 0 15px;
  letter-spacing: 1px;
  cursor: pointer;
  :focus {
    outline: none;
  }
  transition: background-color 0.3s ease;
  :hover {
    background-color: ${colors.green}26;
  }
  ${dimensions.mobile} {
    height: 36px;
    border-radius: 18px;
    font-size: 16px;
    letter-spacing: 2px;
    line-height: 14px;
    width: 260px;
  }
  @media (max-width: 330px) {
    width: 185px;
  }
`;

const LoaderContainer = styled.div`
  margin-top: 5px;
  ${dimensions.mobile} {
    margin-top: 0;
  }
`;

export const LargeButton = ({ text, loading, onClick, width }) => {
  return (
    <Container onClick={onClick} width={width}>
      {loading ? <LoaderContainer><CirclesLoader /></LoaderContainer> : text}
    </Container>
  )
};
