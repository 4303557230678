import React from 'react';
import styled from '@emotion/styled';
import { dimensions } from '../theme/media';

const Text = styled.div`
  font-family: 'Lato';
  font-size: 1.8rem;
  letter-spacing: 0.37rem;
  text-align: ${({ align }) => align || 'center'};
  line-height: 3rem;
  color: ${({ color }) => color};
  ${dimensions.tablet} {
    font-size: 1.5rem;
    letter-spacing: 0.32rem;
    line-height: 2rem;
    text-align: center;
  }
  ${dimensions.mobile} {
    font-size: 1.4rem;
    letter-spacing: 0.29rem;
    line-height: 1.7rem;
    text-align: center;
  }
`;

export const LargeParagraph = ({ children, color, align }) => {
  return <Text color={color} align={align}>{children}</Text>
};
