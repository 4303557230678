import React from 'react';
import styled from '@emotion/styled';
import { Link as GatsbyLink } from 'gatsby'

import { colors } from '../theme/colors';
import { dimensions } from '../theme/media';

const Container = styled.div`
  text-decoration: none !important;
  margin: 0 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${dimensions.mobile} {
    margin: 0;
  }
`;

const StyledLink = styled((props) => <GatsbyLink {...props} />)`
  font-size: 16px;
  letter-spacing: 2px;
  font-family: 'Lato';
  color: ${colors.lightGrey};
  text-decoration: none;
  transition: color 300ms ease;
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  :hover {
    color: ${colors.green};
  }
  ${dimensions.mobile} {
    font-size: 15px;
    margin: 0 8px;
  }
`;

const Underline = styled.div`
  width: 35px;
  height: 2px;
  background-color: ${colors.green};
  margin: 0 auto;
`;

export const Link = ({ pathname, label, to }) => {
  return (
    <Container>
      <StyledLink to={to}>{label}</StyledLink>
      {pathname === to && <Underline />}
    </Container>
  );
};
